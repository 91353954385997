import axios from 'axios';
import Cookies from 'js-cookie';
import isEmpty from 'lodash/isEmpty';
import { useStore } from 'vuex';

const handleServerError = () => {
  return abortNavigation({
    statusCode: 404,
    message: 'Page not found',
  });
};

export default defineNuxtRouteMiddleware(async (to, from) => {
  if (import.meta.client && useNuxtApp().isHydrating && useNuxtApp().payload.serverRendered) return;

  const refererIsPageBuilder = useRequestHeader('referer')?.includes('page-builder');

  const store = useStore();
  if (refererIsPageBuilder) {
    try {
      const idFromRoute = to.params.id;
      const { data: previewConfig } = await axios.get(`${useRuntimeConfig().CMS_PREVIEW_URL}/${idFromRoute}`);
      store.commit('setPageConfig', previewConfig);
      return;
    } catch (ex) {
      return handleServerError();
    }
  }

  if (to.query.openSearch !== from.query.openSearch) {
    return;
  }

  try {
    const slugFromRoute = to.params.slug;
    const cookieHeader = useRequestHeader('cookie');
    const authCookie = useRuntimeConfig().public.IS_INTRANET ? useCookie('auth-token') : null;
    const langCode = !isEmpty(cookieHeader)
      ? `; ${cookieHeader}`.split('; langCode=').pop().split(';').shift()
      : Cookies.get('langCode');
    const secondaryOrgId = store.getters.getSecondaryOrgId;
    const basePagesUrl = `api/v4/o/${secondaryOrgId}/cms/pages`;
    const pageUrl = `${basePagesUrl}/${slugFromRoute}?locale=${langCode}`;
    const intranetBasePagesUrl = `api/v6/secondary_organizations/${secondaryOrgId}/departments/pages`;
    const intranetPageUrl = `${intranetBasePagesUrl}/${slugFromRoute}?locale=${langCode}`;

    const { data } = await useNuxtApp().$axios.get(useRuntimeConfig().public.IS_INTRANET ? intranetPageUrl : pageUrl, {
      cache: { ignoreCache: false },
      headers: authCookie ? { Authorization: authCookie.value } : {},
    });
    const pageConfig = {
      ...(useRuntimeConfig().public.IS_INTRANET ? data.data : data),
      slug: slugFromRoute,
      secOrgId: secondaryOrgId,
      langCode,
    };

    if (pageConfig.status === 'published' || pageConfig.data.status === 'published') {
      store.dispatch('setPageData', pageConfig);
      return;
    } else {
      return handleServerError();
    }
  } catch (ex) {
    return handleServerError();
  }
});
